// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "variables";
@import "fonts";
@import "particles";

html
{
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

a
{
  background-color: transparent;
  color: #949494;
  -o-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -khtml-transition: all 0.5s linear;
  -webkit-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  transition: all 0.5s linear;
  text-decoration: none !important;
  &:active, &:hover
  {
    outline: 0;
    color: #000000;
    text-decoration: none !important;
  }
}

body
{
  font-family: $font-family-primary;
  font-size: 14px;
  line-height: 1.42857;
  color: #494949;
  background: #fff;
}

.pace
{
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pace-inactive
{
  display: none;
}

.pace .pace-progress
{
  background: #000000;
  position: fixed;
  z-index: 9999999;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
}

.pace .pace-progress-inner
{
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #000000, 0 0 5px #000000;
  opacity: 1.0;
  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -moz-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  -o-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

.pace .pace-activity
{
  display: block;
  position: fixed;
  z-index: 9999999;
  top: 15px;
  right: 15px;
  width: 14px;
  height: 14px;
  border: solid 2px transparent;
  border-top-color: #000000;
  border-left-color: #000000;
  border-radius: 10px;
  -webkit-animation: pace-spinner 400ms linear infinite;
  -moz-animation: pace-spinner 400ms linear infinite;
  -ms-animation: pace-spinner 400ms linear infinite;
  -o-animation: pace-spinner 400ms linear infinite;
  animation: pace-spinner 400ms linear infinite;
}

@-webkit-keyframes pace-spinner
{
  0%
  {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100%
  {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes pace-spinner
{
  0%
  {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100%
  {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes pace-spinner
{
  0%
  {
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100%
  {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-ms-keyframes pace-spinner
{
  0%
  {
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100%
  {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes pace-spinner
{
  0%
  {
    transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100%
  {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.logo a::before,
.logo a::after
{
  content: "" !important;
}

/*=======================
       Common
=======================*/

.whiteTxt
{
  color: #fff
}

.spacer-35
{
  margin-top: 35px;
}

.no-margin
{
  margin: 0px;
}

.no-padding
{
  padding: 0px;
}

.border-show
{
  transform: scaleX(1) !important;
}

hr.input-border
{
  position: relative;
  width: 100%;
  top: -1px;
  margin: 0px;
}

.textarea hr.input-border
{
  top: -6px;
}

/* for chrome hidding scrollbar  */
::-webkit-scrollbar
{
  width: 0px !important;
}

.carousel-control.left, .carousel-control.right
{
  background: transparent;
}

.glyphicon-chevron-right, .glyphicon-chevron-left
{
  opacity: 0.5
}

.glyphicon-chevron-right:hover, .glyphicon-chevron-left:hover
{
  opacity: 1
}

/*=======================
       Header
=======================*/
header.main
{
  position: fixed;
  top: 0;
  width: 100%;
  background: #fff;
  z-index: 9999;
  border-bottom: 1px solid #f1f1f1;

}

.absolute
{
  position: absolute;
  z-index: 999999;
  top: 20px;
  right: 30px;
}

.relative
{
  position: relative;
}

.container-full
{
  width: 100%;
}

.logoColor
{
  visibility: hidden;
}

.logo a
{
  padding: 17px 10px 15px !important;
}

.logo a:hover
{
  background-color: transparent !important;
}

.logo a img
{
  max-height: 35px;
  margin-top: -6px;
  opacity: 0.6;
  filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
  -webkit-filter: grayscale(0%);
  transition: .3s;
}

.logo a:hover img
{
  opacity: 1;
  filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale"); /* Firefox 10+, Firefox on Android */
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
  transition: .3s;
}

.logo a:before
{
  content: "";
  margin: 0px;
  clear: both;
}

/*=======================
        NAVIGATION
=======================*/

.menu
{
  margin: 0px;
  padding: 0px;
  a
  {
    font-size: 14px;
    display: block;
    padding: 20px 25px;
    transition: .3s;
    text-transform: uppercase;
    &:active, &:hover
    {
      transition: .3s;

    }
  }
  li
  {
    padding: 0px;
    margin: 0 0px;
  }
}

/*=======================
        HERO SECTION
=======================*/
.video
{
  /*  making the video fullscreen  */
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
}

.hero
{
  height: 100vh;

}

.hero-section
{
  padding: 110px 0;
  h2
  {
    font-family: $font-family-medium;
    font-size: 72px;
    color: #fff;
  }
  .introText
  {
    padding-top: 100px;
  }
}

.btn-outline
{
  border: 2px solid #fff;
  border-radius: 10px;
  color: #fff;
  background: transparent;
}

/*=======================
        Skills
=======================*/

.skills
{
  padding-top: 30px;
  background: #FFDA34;
  padding-bottom: 30px;
  ul
  {
    margin: 0px;
    padding: 0px;
    text-transform: uppercase;
    font-family: $font-family-light;
  }
}

.nopad
{
  padding: 0px;
}

.nomargin
{
  margin: 0px;
}

/*=======================
        Portfolio
=======================*/
.portfolio
{
  background: $light-grey;

  .about-project
  {

    padding-top: 50px;

    h2
    {
      font-size: 85px;
      color: #000;
      letter-spacing: -10px;
      border-right: 1px solid $border;
    }

    h3
    {
      font-size: 25px;
      text-transform: uppercase;
      font-weight: 900;
      color: #000;
    }

    a
    {
      color: #000;
      font-weight: 900;
      text-transform: uppercase;
      padding: 10px 20px;
      border: 4px solid #000;
      display: inline-block;
      margin-top: 60px;
      transition: .3s;
      &:active, &:hover
      {
        background-color: #000;
        transition: .3s;
        color: #fff;
      }
    }

    .project-type
    {
      text-transform: uppercase;
      font-size: 12px;
      margin-bottom: 20px;
      color: $secondary-color;
    }

    p
    {
      width: 75%;
      line-height: 24px;
    }

  }

}

.carousel-indicators li
{
  border-color: #000;
}

.carousel-indicators .active
{
  background-color: #000;
}

.everything
{
  padding: 100px 0px;
  background-color: #fff;

  a
  {
    border: 1px solid $border;
    border-radius: 50px;
    padding: 15px 30px;
    font-size: 25px;
    color: $primary-color;
    transition: .3s;
    &:active, &:hover
    {
      background-color: #000;
      transition: .3s;
      color: #fff;
      border-color: #000;
    }
  }
}

/*=======================
        Solutions
=======================*/

.solutions
{

  background-color: #fff;
  border-top: 1px solid $border;
  padding: 50px 0px;

  .border
  {
    background-color: $secondary-color;
    height: 3px;
    width: 5%;
    margin: 0px auto 35px;
  }

  p
  {
    margin-bottom: 25px;
    line-height: 24px;
  }

  .box-custom
  {

    .main
    {
      text-transform: uppercase;
      color: #000;
      font-size: 20px;
      margin: 0px;
      margin-top: 10px;
      padding: 10px;
    }

    .border
    {
      background-color: $border;
      height: 3px;
      width: 10%;
      margin: 0px auto 12px;
    }

    .sub-head
    {
      text-transform: uppercase;
      font-size: 12px;
      color: $secondary-color;
    }

  }

}

.rotating
{
  display: inline-block;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateX(0) rotateY(0) rotateZ(0);
  -moz-transform: rotateX(0) rotateY(0) rotateZ(0);
  -ms-transform: rotateX(0) rotateY(0) rotateZ(0);
  -o-transform: rotateX(0) rotateY(0) rotateZ(0);
  transform: rotateX(0) rotateY(0) rotateZ(0);
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform-origin-x: 50%;
}

.rotating.flip
{
  position: relative;
}

.rotating .front, .rotating .back
{
  left: 0;
  top: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}

.rotating .front
{
  position: absolute;
  display: inline-block;
  -webkit-transform: translate3d(0, 0, 1px);
  -moz-transform: translate3d(0, 0, 1px);
  -ms-transform: translate3d(0, 0, 1px);
  -o-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
}

.rotating.flip .front
{
  z-index: 1;
}

.rotating .back
{
  display: block;
  opacity: 0;
}

.rotating.spin
{
  -webkit-transform: rotate(360deg) scale(0);
  -moz-transform: rotate(360deg) scale(0);
  -ms-transform: rotate(360deg) scale(0);
  -o-transform: rotate(360deg) scale(0);
  transform: rotate(360deg) scale(0);
}

.rotating.flip .back
{
  z-index: 2;
  display: block;
  opacity: 1;

  -webkit-transform: rotateY(180deg) translate3d(0, 0, 0);
  -moz-transform: rotateY(180deg) translate3d(0, 0, 0);
  -ms-transform: rotateY(180deg) translate3d(0, 0, 0);
  -o-transform: rotateY(180deg) translate3d(0, 0, 0);
  transform: rotateY(180deg) translate3d(0, 0, 0);
}

.rotating.flip.up .back
{
  -webkit-transform: rotateX(180deg) translate3d(0, 0, 0);
  -moz-transform: rotateX(180deg) translate3d(0, 0, 0);
  -ms-transform: rotateX(180deg) translate3d(0, 0, 0);
  -o-transform: rotateX(180deg) translate3d(0, 0, 0);
  transform: rotateX(180deg) translate3d(0, 0, 0);
}

.rotating.flip.cube .front
{
  -webkit-transform: translate3d(0, 0, 100px) scale(0.9, 0.9);
  -moz-transform: translate3d(0, 0, 100px) scale(0.85, 0.85);
  -ms-transform: translate3d(0, 0, 100px) scale(0.85, 0.85);
  -o-transform: translate3d(0, 0, 100px) scale(0.85, 0.85);
  transform: translate3d(0, 0, 100px) scale(0.85, 0.85);
}

.rotating.flip.cube .back
{
  -webkit-transform: rotateY(180deg) translate3d(0, 0, 100px) scale(0.9, 0.9);
  -moz-transform: rotateY(180deg) translate3d(0, 0, 100px) scale(0.85, 0.85);
  -ms-transform: rotateY(180deg) translate3d(0, 0, 100px) scale(0.85, 0.85);
  -o-transform: rotateY(180deg) translate3d(0, 0, 100px) scale(0.85, 0.85);
  transform: rotateY(180deg) translate3d(0, 0, 100px) scale(0.85, 0.85);
}

.rotating.flip.cube.up .back
{
  -webkit-transform: rotateX(180deg) translate3d(0, 0, 100px) scale(0.9, 0.9);
  -moz-transform: rotateX(180deg) translate3d(0, 0, 100px) scale(0.85, 0.85);
  -ms-transform: rotateX(180deg) translate3d(0, 0, 100px) scale(0.85, 0.85);
  -o-transform: rotateX(180deg) translate3d(0, 0, 100px) scale(0.85, 0.85);
  transform: rotateX(180deg) translate3d(0, 0, 100px) scale(0.85, 0.85);
}

span.rotate
{
  // background: #FFDA34;
  // color: #000;
  // padding-left: 10px;
  // padding-right: 10px;
  // padding-top: 5px;
  // padding-bottom: 5px;
  // margin: 0 auto;
  // text-align: center;
  // margin-stop:25px;
  // display: block;
  // max-width: 500px;

}

/*=======================
        About
=======================*/

.about
{

  // background-color: rgba(0, 0, 0, 0.8);
  background: url('../images/about-bg.png') repeat top center #fff;
  border-top: 1px solid $border;
  padding: 75px 0px;
  color: $primary-color;

  p
  {
    // text-align: justify;
    line-height: 30px;
  }

  .border
  {
    background-color: $secondary-color;
    height: 3px;
    width: 5%;
    margin: 0px auto 35px;
  }

  h2
  {
    margin-top: 0px;
  }

  .round-btn:hover
  {
    border-color: $border;
  }

}

h2.text-center
{
  text-transform: uppercase;
}

.portfolio
{
  position: relative;
}

.row.no-margin:hover .overlay
{

  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  transition: background-color 1s ease;

}

/*=======================
        Enquiry
=======================*/

.enquiry
{

  background-color: #fff;
  border-top: 1px solid $border;
  padding: 75px 0px;
  color: $primary-color;

  .border
  {
    background-color: $secondary-color;
    height: 3px;
    width: 5%;
    margin: 0px auto 35px;
  }

  h2
  {
    margin-top: 0px;
    // color: #000;
  }
}

footer
{
  background-color: #fff;
  padding: 20px;
  border-top: 1px solid $border;
  .footerList
  {
    margin: 0px;
    padding: 0px;
  }
  p
  {
    margin: 0px;
  }

  .footer-links
  {

    margin: 0px;

    li
    {
      width: auto;
      text-align: left;
      padding: 0 10px;

      a img
      {
        transform: scale(0);
        transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
      }

      a:hover img
      {
        transform: scale(1);
        transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
      }
    }

    li:last-child
    {
      padding-right: 0px;
    }
  }
}

/*=======================
        Connect
=======================*/

.page-start
{
  margin-top: 61px;
  min-height: 80vh;

  h2.page-title
  {
    // font-size: 35px;
    // margin: 65px 0px 50px;
    font-size: 35px;
    margin: 50px 0px 0px;
    text-align: center;
    text-transform: uppercase;
  }

  ol
  {
    margin-bottom: 50px;
    background-color: transparent;

    .active a
    {
      color: #000;
    }
  }
}

.connect
{

  .container
  {
    padding-right: 0px;
  }
}

.form-group
{
  margin-bottom: 50px;
}

.custom-input
{
  border: none;
  border-bottom: 1px solid #e6e6e6;
  width: 100%;
  font-size: 17px;
  padding: 10px 30px;
  outline: none;
}

.map
{
  height: 100vh;
  background: url('../images/map.jpg') no-repeat center center;
  background-size: cover;
  position: relative;
}

.round-btn
{
  border: 1px solid $border;
  border-radius: 50px;
  padding: 8px 25px;
  font-size: 17px;
  color: #555555;
  background-color: #fff;
  transition: .3s;

  &:active, &:hover
  {
    background-color: #000;
    transition: .3s;
    color: #fff;
    border-color: #000;
  }
}

.contact-details-container
{
  position: relative;
  height: 100%;
  max-height: 645px;

  .contact-details
  {
    position: absolute;
    bottom: 55px;

    p
    {

      color: #fff;

      strong
      {
        color: #B7B7B7;
      }
    }
  }
}

/*=======================
        About
=======================*/

.about-page
{

  background-color: #fff;

  p
  {
    line-height: 30px;
  }

  .ia-images img
  {
    margin: 0 0 0 auto;
  }
}

/*=======================
        Case Studies
=======================*/
.case-studies
{

  .about-project
  {
    padding: 0px;

    a
    {
      margin-top: 15px;
    }

  }

}

/*=======================
        Solutions
=======================*/

.channels
{
  border: 1px solid #f4f4f4;
  margin-bottom: 15px;
  text-align: center;
  background-color: #fff;
  transition: 0.2s;
}

.channels img
{
  margin-top: 20px;
  margin-bottom: 10px;
  /* padding-bottom: 25px; */
  border-bottom: 4px solid #e7e7e7;
  max-width: 150px;
  transition: 0.2s;
}

.channels:hover
{
  background-color: $light-grey;
  transition: 0.2s;
}

.channels .hover
{
  display: none;
  transition: 0.2s;
}

.channels:hover img
{
  display: none;
  transition: 0.2s;
}

.channels:hover .hover
{
  display: block;
  transition: 0.2s;
}

.channels ul.no-style
{
  list-style: none;
  margin-bottom: 20px;
  padding-left: 0px;
}

.tech-badges
{
  background-color: #fff;
  border-top: 1px solid $border;
  padding: 50px 0px;
  color: $primary-color;
}

.hidden
{
  display: none;
}

/*flash*/

.alert-error
{
  background-color: #f2dede;
  border-color: #eed3d7;
  color: #b94a48;
  text-align: left;
}

.alert-alert
{
  background-color: #f2dede;
  border-color: #eed3d7;
  color: #b94a48;
  text-align: left;
}

.alert-success
{
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #468847;
  text-align: left;
}

.alert-notice
{
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #468847;
  text-align: left;
}

// Menu Hover CSS
// 

/* Effect 1: Brackets */
.cl-effect-1 a::before,
.cl-effect-1 a::after
{
  display: inline-block;
  opacity: 0;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.2s;
  -moz-transition: -moz-transform 0.3s, opacity 0.2s;
  transition: transform 0.3s, opacity 0.2s;
}

.cl-effect-1 a::before
{
  margin-right: 10px;
  content: '[';
  -webkit-transform: translateX(20px);
  -moz-transform: translateX(20px);
  transform: translateX(20px);
}

.cl-effect-1 a::after
{
  margin-left: 10px;
  content: ']';
  -webkit-transform: translateX(-20px);
  -moz-transform: translateX(-20px);
  transform: translateX(-20px);
}

.cl-effect-1 a:hover::before,
.cl-effect-1 a:hover::after,
.cl-effect-1 a:focus::before,
.cl-effect-1 a:focus::after
{
  opacity: 1;
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  transform: translateX(0px);
}

.breadcrumb li:before:first-child
{
  content: "";
}


@-moz-keyframes three-quarters-loader
{
  0%
  {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100%
  {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes three-quarters-loader
{
  0%
  {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100%
  {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes three-quarters-loader
{
  0%
  {
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100%
  {
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/* :not(:required) hides this rule from IE9 and below */
.three-quarters-loader:not(:required)
{
  -moz-animation: three-quarters-loader 1250ms infinite linear;
  -webkit-animation: three-quarters-loader 1250ms infinite linear;
  animation: three-quarters-loader 1250ms infinite linear;
  border: 4px solid #38e;
  border-right-color: transparent;
  border-radius: 16px;
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  overflow: hidden;
  text-indent: -9999px;
  width: 18px;
  height: 18px;
}